
import Cookies from '@/utils/cookie'
// import { auyFun, sell } from '@/api'
// import turntableBgc from "@s/img/sign/bg.png";
// import turntableBgc from "@s/img/index/examine.png";
// import turntablebtn from "@s/img/index/dzpanniu.png";
import animaIndex from "@/components/animation/animaIndex";
import countdownDzp from "@/components/countdown/countdownDzp";
export default {
  props: {
    custom: {
      type: Boolean,
      default: false
    },
    showfa: Number
  },
  components: { animaIndex, countdownDzp },
  data () {
    return {
      animaBol: false,
      jiangTime: 24 * 60 * 60 * 1000,
      cg_prize: "",
      tip1: "",
      tip2: "",
      cg_tips: "",
      showCg: true,
      visible: false,
      goods_id: "",
      award_state: "",
      award_explain: "",
      can_award_num: 0,
      falg: true,
      tishitips: "",//提示
      pIndex: 0, // 中奖物品的下标
      rotNum: 0, // 旋转圈数基数
      time: 3500, // 旋转时间
      timer: null, // 定时器
      oTurntable: "", // 旋转圆盘背景图
      type: 0, // 0 图片 1 汉字
      activeObj: {
        turntableBgc: "",
        turntablebtn: "",
        prizeData: [
          {
            prize: "10元",
            id: 0,
          },
          {
            prize: "30元",
            id: 1,
          },
          {
            prize: "50元",
            id: 2,
          },
          {
            prize: "100元",
            id: 3,
          },
          {
            prize: "10元",
            id: 4,
          },
          {
            prize: "30元",
            id: 5,
          },
          {
            prize: "50元",
            id: 6,
          },
          {
            prize: "100元",
            id: 7,
          },
        ],
      },
    };
  },
  created () {
    this.dzpinfo();

  },
  computed: {
    // ...mapState('loginInfo', ['couponInfo', 'userInfo']),
    // is_conpon () {
    //   return this.$store.state.loginInfo.userInfo
    // },
  },
  watch: {
    custom: {
      handler (newV, oldV) {

      },
      immediate: true,
    },
    showfa: {
      handler (newV, oldV) {
        if (newV == 1) {
          if (process.client) {
            let bb = sessionStorage.getItem('coupon_bol')
            let cc = Cookies.get('coupon_bol');
            if (bb == 1 || cc == 1) {
              this.visible = false
            } else {
              this.visible = true
            }
          }

        }
      },
      immediate: true,
    },
    //登陆之后

  },
  //渲染完了
  mounted () {
    if (process.client) {
      this.activeObj.prizeData = this.autoRotate(this.activeObj.prizeData);
      this.oTurntable = document.querySelector("#turnUl");
      // 过度中属性用时5s
      this.oTurntable.style.webkitTransition =
        "transform " + this.time / 1000 + "s ease";
    }

  },
  methods: {
    updatejiang () {
      this.jiangTime = 24 * 60 * 60 * 1000
    },
    tolink () {
      this.visible = false
      this.$set(this.$store.state.loginInfo.userInfo, 'is_coupon', 0)
      this.$customJump({
        path: '/content/Promotion',
      })
    },
    async dzpinfo () {
      // var self = this
      // plz //显示zipcode错误提示
      //chectBol 显示输入的zipcode是否存在
      let { data, code, msg } = await this.$api.auyFun.DZPINFO()
      if (data.code != 200) {
        // this.$message.warning(msg)
      } else {
        this.activeObj.turntableBgc = data.data.prize_img
        this.activeObj.turntablebtn = data.data.prize_btn
        this.tishitips = data.data.prize_win
        this.tip1 = data.data.tip1
        this.tip2 = data.data.tip2
      }
    },
    async startPlay () {
      if (this.falg) {
        let a = {}
        this.falg = false;
        var self = this
        a = {
          id: 0,
        };
        sessionStorage.setItem('coupon_bol', 1)
        Cookies.set('coupon_bol', 1, { expires: 1 });
        localStorage.setItem('centerDialogVisibletime', 1)
        self.startBtn(a);
        // self.$set(self.$store.state.loginInfo.userInfo, 'is_coupon', 0)
      }
    },
    //点击开始,请求接口抽奖
    // async startPlay () {
    //   if (this.falg) {
    //     let a = {}
    //     this.falg = false;
    //     var self = this
    //     let { data, code, msg } = await auyFun.DZPDRAW()
    //     if (code != 200) {
    //       self.falg = true;
    //       message.warning(msg)
    //     } else {
    //       if (Number(data.prize) >= 100) {
    //         self.cg_tips = self.tip1
    //       } else {
    //         self.cg_tips = self.tip2

    //       }
    //       self.cg_prize = data.prize
    //       a = {
    //         id: data.id,
    //         prize: data.prize,
    //       };
    //       localStorage.setItem('centerDialogVisibletime', 1)
    //       self.startBtn(a);
    //       self.$set(self.$store.state.loginInfo.userInfo, 'is_coupon', 0)
    //     }
    //   }
    // },

    closeFun () {
      // upShow
      sessionStorage.setItem('coupon_bol', 1)
      Cookies.set('coupon_bol', 1, { expires: 1 });
      this.$emit('dzp_visible', false)
      this.visible = false
      this.$Bus.$emit('dzp_logid', 2346)

    },
    tolinj () {
      if (this.award_state == 1) {
      } else if (this.award_state == 2) {
        this.$customJump({ path: "/content/homeClass", query: { id: this.goods_id } });
      } else if (this.award_state == 3) {
        this.$customJump({ path: "/content/assets" });
      }
    },
    onClickLeft () {
      this.$router.go(-1);
    },
    //自动生成角度添加到数组上
    autoRotate (arr) {
      if (arr.length) {
        let len = arr.length;
        let base = 360 / len;
        arr.forEach((item, index) => {
          // 指的是某个奖品区域的中间 : base/2
          item.angle = 360 - (base / 2 + index * base);
        });
      }

      return arr;
    },
    awardInfo () {
      let appTruePass = window.localStorage.getItem("appTruePass");
      let myID = window.localStorage.getItem("myID");
      var self = this;
      var data = {
        myID: myID,
        appTruePass: appTruePass,
      };
      fly.AxiosC({
        url: self.$api.HOTEL.awardInfo,
        data: {
          data,
        },
        success: (res) => {
          if (res.code == "200") {
            self.can_award_num = res.data.can_award_num;
            self.award_explain = res.data.award_explain;
            self.award_state = res.data.award_state;
            self.goods_id = res.data.goods_id;
            if (!res.data.isshow) {
              Dialog.alert({
                showConfirmButton: false,
                message:
                  " 系统公告：由于发现有用户虚假邀请，影响活动公平性，现暂时暂停邀请活动，等待系统升级完成重新开放！",
              }).then(() => {
                // on confirm
              });
            }
            // self.objk = res.data;
          }
        },
      });
    },
    // 点击开始,请求接口抽奖弃用
    startPlay1 () {
      if (this.can_award_num != 0 && this.falg) {
        this.falg = false;
        var a = {};
        var self = this;
        var data = {
          myID: myID,
          appTruePass: appTruePass,
        };
        fly.AxiosC({
          url: self.$api.HOTEL.award,
          data: {
            data,
          },
          success: (res) => {
            if (res.code == "200") {
              self.award_state = res.data.award_state;
              self.goods_id = res.data.goods_id;
              // res.data.amount = 1000;
              if (res.data.award_level == 2) {
                a = {
                  id: res.data.award_level,
                  prize: res.data.award_name,
                };
              } else if (res.data.award_level == 1) {
                a = {
                  id: res.data.award_level,
                  prize: res.data.award_name,
                };
              } else {
                a = {
                  id: res.data.award_level,
                  prize: res.data.award_name,
                };
              }
              self.startBtn(a);
            } else {
              self.falg = true;
              self.$toast(res.msg);

              // if (res.msg == "请先升级为精英会员") {
              //   setTimeout(() => {
              //     self.$customJump({ path: "sVip" });
              //   }, 1000);
              // }
            }
          },
        });
        // const a = {
        //   prize: "50元和包券",
        //   id: 4
        // };
      } else {
        let a = {
          id: 4,
          prize: "ceshi",
        };
        this.startBtn(a);
      }
    },
    // 开始转动,通过奖项级别进行匹配:id
    startBtn (val) {
      const self = this;
      self.activeObj.prizeData.forEach((i, d) => {
        if (i.id == val.id) {
          self.pIndex = d;
        }
      });
      // 拿到相应的角度调旋转接口
      self.startrotate(self.activeObj.prizeData[self.pIndex].angle, () => {
        // self.fulfillHandle(self.activeObj.prizeData[self.pIndex].prize);
        self.fulfillHandle(val.prize);
      });
    },

    //开始旋转 angle角度  complete回调成功函数
    startrotate (angle, complete) {
      var ro = 40; //此处是设计稿角度不同用来修正角度
      // 相应的角度 + 满圈 只是在原角度多转了几圈 360 * 6
      let rotate = 2160 * (this.rotNum + 1) + angle + ro;
      this.oTurntable.style.webkitTransform = "rotate(" + rotate + "deg)";
      clearTimeout(this.timer);
      // 设置5秒后停止旋转,处理接口返回的数据
      this.timer = setTimeout(() => {
        complete();
        this.rotNum++;
        ro = 0;
      }, this.time);
    },
    //得奖后的处理
    fulfillHandle (prizeName) {
      this.falg = true;
      this.animaBol = true
      // this.$toast(prizeName);
      this.showCg = false;
      this.can_award_num = 0;
      this.$Bus.$emit('dzp_logid', 2346)
      // this.$emit("result", prizeName);
    },
    open () {
      this.$alert('这是一段内容', '标题名称', {
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${action}`
          });
        }
      });
    },

    //自动换行
    autoWrap (str) {
      if (str.length > 5 && str.length <= 10) {
        str = str.substring(0, 5) + "<br/>" + str.substring(5, str.length);
      } else if (str.length > 10) {
        str = str.substring(0, 5) + "<br/>" + str.substring(5, 9) + "...";
      }
      return str;
    },
  }
};
