
//导入swiper
var vm = null;
export default {
  components: {
    // VueAwesomeSwiper
  },
  // 
  name: 'shopswiper',
  props: {
    bestList: {
      type: Array,
      default: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    tips: String
  },
  data () {
    return {
      originalSpeed: 0,
      swiperOptions: {
        speed: 5000, //匀速时间
        freeMode: true,
        loop: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        on: {
          click: (e, k) => {
            vm.handleClickCard(e)
          }
        },
        slidesPerView: 7,
        centeredSlides: true,
      }
    };
  },
  created () {
    vm = this
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.$refs.mySwiper.swiperInstance = this.$refs.mySwiper.swiper; // 获取 Swiper 实例
    // });
    // this.$nextTick(() => {
    //   this.$refs.mySwiper.swiper = this.$refs.swiper.mySwiper;
    // });
    // let this_swiper = this.$refs.mySwiper.swiper
    // this_swiper.el.onmouseover = function () {
    //   console.log(1)
    //   this_swiper.autoplay.stop()
    // }
    // this_swiper.el.onmouseout = function () {
    //   console.log(2)
    //   this_swiper.autoplay.start();
    // }
  },

  methods: {
    handleClickCard (e) {
      let cardDataJSON = e.target.getAttribute('data-card')
      if (cardDataJSON) {
        this.toclass(cardDataJSON)
      }
      // console.log(e.target.dataset.card, e.target.getAttribute('data-card'))
      // let cardData = JSON.parse(cardDataJSON)
      // console.log(cardData)
    },
    mouseEnter () {
      // debugger
      // this.$refs.mySwiper.swiper.setTransition(20);
      this.$refs.mySwiper.swiper.autoplay.stop();
      this.$refs.mySwiper.swiper.setTranslate(this.$refs.mySwiper.swiper.getTranslate());//鼠标移入立刻停止滚动
      this.originalSpeed = this.$refs.mySwiper.swiper.params.speed; // 保存原始速度
      this.$refs.mySwiper.swiper.params.speed = 0; // 暂时设置速度为 0
    },

    mouseLeave () {

      // this.$refs.mySwiper.swiper.autoplay.start();
      this.$refs.mySwiper.swiper.params.speed = this.originalSpeed; // 恢复原始速度
      this.$refs.mySwiper.swiper.autoplay.start(); // 恢复自动播放
      this.$refs.mySwiper.swiper.update(); // 更新 Swiper 实例
      this.$refs.mySwiper.swiper.slideNext(); // 强制跳到下一张幻灯片
    },
    async addaction (buryingPoint) {
      var self = this
      await self.$api.auyFun.ADDACTION(buryingPoint).then((res) => {
      })
    },
    toclass (k) {
      if (k) {
        let buryingPoint = {
          from_page: "index",
          current_page: "",
          area: "Categories",
          id_value: k,
          value_type: "2"
        }
        this.addaction(buryingPoint)
        // this.$customJump({
        //   path: '/content/classify',
        //   query: { cat_id: k }
        // })
      }

    },
    seemore () {
      this.$customJump({
        path: '/content/classify',
      })
    }
  },

};
